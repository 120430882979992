import React from 'react';
import { withTranslation } from 'react-i18next';
import ArrowButton from '../component/ArrowButton'
import ScrollContainer from 'react-indiana-drag-scroll'
import './HomeReferences.css';

class HomeReferences extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        {
            src: [
                {'path': '/images/logos/HE.webp'},
                {'path': '/images/logos/SpringerFachmedien.webp'},
                {'path': '/images/logos/WendelVerlag.webp'},
                {'path': '/images/logos/Vogel.webp'},
                {'path': '/images/logos/EZAgrar.webp'},
                {'path': '/images/logos/Gutwinski.webp'},
                {'path': '/images/logos/Airbus.webp'},
                {'path': '/images/logos/Logo_Wels.webp'},
                {'path': '/images/logos/SteigEinFahrApp.webp'},
                {'path': '/images/logos/Varena.webp'},
                {'path': '/images/logos/bmvit.webp'},
                {'path': '/images/logos/StadttheaterGreif.webp'},
                {'path': '/images/logos/SchieneOOE.webp'},
                {'path': '/images/logos/Hennlich.webp'},
                {'path': '/images/logos/Lanzendorfer.webp'},
                {'path': '/images/logos/Lollipop.webp'},
                {'path': '/images/logos/EskoleLeiner.webp'},
                {'path': '/images/logos/RaiffeisenbankWelsSued.webp'},
                {'path': '/images/logos/Hitzinger.webp'},
                {'path': '/images/logos/POSSCREEN.webp'}
            ]
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.startAnimation);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.startAnimation);
    }

    startAnimation() {
        let element = document.querySelector('.App-home-references-tabs');
        let position = element.getBoundingClientRect();
        if (position.top >= 0 && position.bottom <= window.innerHeight) {
            element.classList.add('animation')
        } else {
            element.classList.remove('animation')
        }
    }

    getNameFromPath(path) {
        try {
            if (path && path.length > 0) {
                return path.split('/').pop().split('.').shift();
            }
        }
        catch (e) {}
        return '';         
    }

    render() {
        let tabItems = [];
        for (let i = 0; i < this.state.src.length; i++) {
            let tabItemSrc = this.state.src[i].path;
            let tabItemAlt = this.getNameFromPath(tabItemSrc);
            let tabItem = (
                <div key={`tab-item-${i}`} className="App-home-references-tab-item">
                    <img src={tabItemSrc} alt={tabItemAlt} />
                </div>
            );
            tabItems.push(tabItem);
        }

        return (
            <div className="App-home-references">
                <div className="App-home-references-text">{this.props.t('HomeReferences.Header')}</div>
                <div className="App-home-references-tabs-container">
                    <ScrollContainer className="App-home-references-tabs">
                        {tabItems}
                    </ScrollContainer>
                </div>
                <div className="App-home-references-subheader eurostile-condensed-heavy">{this.props.t('HomeReferences.Content.Title')}</div>
                <div className="App-home-references-text">{this.props.t('HomeReferences.Content.SubTitle')}</div>
                <div className="App-home-references-button-container">
                    <ArrowButton className="ArrowButton-more" text={this.props.t('Common.More')} navigateto="/references" />
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomeReferences);