import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import lottie_team from '../media/lottie_45534_team.json';
import Slogan from '../component/Slogan'
import './HomeIntro.css';

export default class HomeIntro extends React.Component {
    render() {
        return (
            <div className="App-home-intro">
                <Slogan className="App-home-intro-slogan" hasAnimation={true} />
                <div className="App-home-intro-animation">
                    <Player autoplay loop src={lottie_team} />
                </div>
            </div>
        );
    }
}