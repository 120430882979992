import React from 'react';
import ArrowButton from '../component/ArrowButton'
import { Player } from '@lottiefiles/react-lottie-player';
import { withTranslation } from 'react-i18next';
import lottie_developer from '../media/lottie_91451_developer_blue.json';
import './HomeCareer.css';

class HomeCareer extends React.Component {
    render() {
        return (
            <div className="App-home-career">
                <div className="App-home-career-animation">
                    <Player autoplay loop src={lottie_developer} />
                </div>  
                <div className="App-home-career-content">
                    {this.props.t('HomeCareer.Header')}
                </div>
                <div style={{textAlign: 'right'}}>
                    <ArrowButton className="ArrowButton-more" text={this.props.t('Common.More')} navigateto="/career" />
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomeCareer);