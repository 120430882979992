import React from 'react';
import PropTypes from 'prop-types';
import './BubbleContainer.css';

export default class BubbleContainer extends React.Component {
    render() {
        let bubbleContainerId = null;
        if (this.props.id && this.props.id.length > 0) {
            bubbleContainerId = this.props.id;
        }
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        let headerDiv;
        if (this.props.header && this.props.header.length > 0) {
            headerDiv = (
                <div className="BubbleContainer-header">
                    <span className="eurostile-condensed-heavy">{this.props.header}</span>
                </div>
            );
        }
        let iconDiv;
        if (this.props.iconsrc && this.props.iconsrc.length > 0) {
            iconDiv = (
                <div className="BubbleContainer-bubble-icon">
                    <img src={this.props.iconsrc} alt="icon" />
                </div>
            );
        }
        return (
            <div id={bubbleContainerId} className={`BubbleContainer${extraClassName}`}>
                <div className="BubbleContainer-bubble">
                    {iconDiv}
                </div>
                <div className="BubbleContainer-inner">
                    {headerDiv}
                    {this.props.children}
                </div>
                <div className="BubbleContainer-left"></div>
            </div>
        );
    }
}

BubbleContainer.defaultProps = {
    header: '',
    iconsrc: ''
};

BubbleContainer.propTypes = {
    header: PropTypes.string,
    iconsrc: PropTypes.string
}