import React from 'react';
import PropTypes from 'prop-types';
import arrow_left_000000 from '../media/arrow_left_000000.svg';
import arrow_left_ffffff from '../media/arrow_left_ffffff.svg';
import arrow_right_000000 from '../media/arrow_right_000000.svg';
import arrow_right_ffffff from '../media/arrow_right_ffffff.svg';
import './Carousel.css';

export default class Carousel extends React.Component {

    constructor(props) {
        super(props);
        this.state =
        {
            currentIndex: 0,
            currentSrc: '',
            currentAlt: '',
            currentHeader: '',
            currentText: '',
            hasCaption: false
        };
        this.getCurrentIndex = this.getCurrentIndex.bind(this);
        this.getNextIndex = this.getNextIndex.bind(this);
        this.getPrevIndex = this.getPrevIndex.bind(this);
        this.countItems = this.countItems.bind(this);
        this.getItem = this.getItem.bind(this);
        this.hasItem = this.hasItem.bind(this);
        this.getPath = this.getPath.bind(this);
        this.getHeader = this.getHeader.bind(this);
        this.getText = this.getText.bind(this);
        this.leftClick = this.leftClick.bind(this);
        this.rightClick = this.rightClick.bind(this);
        this.previewItemClick = this.previewItemClick.bind(this);
        this.setItem = this.setItem.bind(this);
        this.setItem(0, this.state);
    }

    getCurrentIndex() {
        return this.state.currentIndex;
    }

    getNextIndex(offset) {
        if (typeof offset === 'undefined') {
            offset = 1;
        }
        let count = this.countItems();
        if (count === 0) {
            return 0;
        }
        let nextIndex = (this.state.currentIndex + offset) % count;
        return nextIndex;
    }

    getPrevIndex(offset) {
        if (typeof offset === 'undefined') {
            offset = 1;
        }
        let count = this.countItems();
        if (count === 0) {
            return 0;
        }
        let prevIndex = (this.state.currentIndex - offset) % count;
        if (prevIndex >= 0) {
            return prevIndex;
        }
        return count + prevIndex;
    }

    countItems() {
        if (this.props.src && this.props.src.length > 0) {
            return this.props.src.length;
        }
        return 0;
    }

    getItem(index) {
        let count = this.countItems();
        if (count > 0 && count > index && index >= 0) {
            return this.props.src[index];
        }
        return undefined;
    }

    hasItem(index) {
        let item = this.getItem(index);
        if (item) {
            return true;
        }
        return false;
    }

    getPath(index) {
        let item = this.getItem(index);
        if (item && item.path) {
            return item.path;
        }
        return undefined;
    }

    getHeader(index) {
        let item = this.getItem(index);
        if (item && item.header) {
            return item.header;
        }
        return undefined;
    }

    getText(index) {
        let item = this.getItem(index);
        if (item && item.text) {
            return item.text;
        }
        return undefined;
    }

    getNameFromPath(path) {
        try {
            if (path && path.length > 0) {
                return path.split('/').pop().split('.').shift();
            }
        }
        catch (e) {}
        return '';         
    }

    leftClick() {
        this.setItem(this.getPrevIndex(), undefined);
    }

    rightClick() {
        this.setItem(this.getNextIndex(), undefined);
    }

    previewItemClick(index) {
        this.setItem(index, undefined);
    }

    setItem(index, state) {
        if (this.hasItem(index) === false) {
            return;
        }
        if (typeof state === 'undefined') {
            state = {};
        }
        state.currentIndex = index;
        state.currentSrc = this.getPath(index);
        state.currentAlt = this.getNameFromPath(state.currentSrc);
        state.currentHeader = this.getHeader(index);
        state.currentText = this.getText(index);
        if ((state.currentHeader && state.currentHeader.length > 0) || (state.currentText && state.currentText.length > 0)) {
            state.hasCaption = true;
        } else {
            state.hasCaption = false;
        }
        this.setState(state);
    }

    render() {
        let carouselId = null;
        if (this.props.id && this.props.id.length > 0) {
            carouselId = this.props.id;
        }
        let extraClassName = '';
        if (this.props.className && this.props.className.length > 0) {
            extraClassName = ' ' + this.props.className;
        }
        const carouselStyle = {
            backgroundColor: this.props.backgroundColor
        };
        let aspectRatio = (Math.round(this.props.aspectRatio * 10000) / 100) + '%';
        const imgContainerStyle = {
            paddingTop: aspectRatio
        };
        let imgDiv;
        if (this.state.currentSrc && this.state.currentSrc.length > 0) {
            imgDiv = (
                <img className="Carousel-img" src={this.state.currentSrc} alt={this.state.currentAlt} />
            );
        }
        let arrowLeftDiv;
        let arrowRightDiv;
        let indicatorDiv;
        let count = this.countItems();
        if (count > 1) {
            if (this.props.hideNavigation === false) {
                arrowLeftDiv = (
                    <div className="Carousel-arrow-left" onClick={this.leftClick}>
                        <img src={arrow_left_000000} className="normal" alt='arrow-left' />
                        <img src={arrow_left_ffffff} className="hover" alt='arrow-left-hover' />
                    </div>
                );
                arrowRightDiv = (
                    <div className="Carousel-arrow-right" onClick={this.rightClick}>
                        <img src={arrow_right_000000} className="normal" alt='arrow-right' />
                        <img src={arrow_right_ffffff} className="hover" alt='arrow-right-hover' />
                    </div>
                );
            }
            if (this.props.hideIndicator === false) {
                let indicatorItems = [];
                for (let i = 0; i < count; i++) {
                    let indicatorItem = (
                        <div key={`indicator-item-${i}`} className={`Carousel-indicator-item Carousel-indicator-item-${i}${this.state.currentIndex === i ? ' active' : ''}`}>
                        </div>
                    );
                    indicatorItems.push(indicatorItem);
                }
                indicatorDiv = (
                    <div className="Carousel-indicator">
                        {indicatorItems}
                    </div>
                );
            }
        }
        let previewDiv;
        if (this.props.hidePreview === false) {
            let previewItems = [];
            for (let i = 0; i < this.props.previewRows; i++) {
                let previewItemIndex = this.getNextIndex(i + 1);
                let previewItemSrc = this.getPath(previewItemIndex);
                let previewItemAlt = this.getNameFromPath(previewItemSrc);
                let previewItem = (
                    <div key={`preview-item-${i}`}  className={`Carousel-preview-item Carousel-preview-item-${i}`} onClick={() => this.previewItemClick(previewItemIndex)}>
                        <img src={previewItemSrc} alt={previewItemAlt}/>
                    </div>
                );
                previewItems.push(previewItem);
            }
            const previewStyle = {
                width: (this.props.previewWidth - 15) + 'px',
                marginRight: (this.props.previewWidth * -1) + 'px'
            };
            previewDiv = (
                <div className={`Carousel-preview rows-${this.props.previewRows}`} style={previewStyle}>
                    {previewItems}
                </div>
            );
        }
        let captionTopDiv;
        let captionBottomDiv;
        let captionDiv = (
            <div className={`Carousel-caption-container${this.state.hasCaption ? ' active' : ''}`}>
                <div className="Carousel-caption-header eurostile-condensed-heavy">{this.state.currentHeader}</div>
                <div className="Carousel-caption-text">{this.state.currentText}</div>
            </div>
        );
        if (this.props.captionPosition === 'top') {
            captionTopDiv = captionDiv;
        } else {
            captionBottomDiv = captionDiv;
        }
        return (
            <div id={carouselId} className={`Carousel${extraClassName}`} style={carouselStyle}>
                {captionTopDiv}
                <div className="Carousel-img-container-outer" style={this.props.hidePreview ? {} : { marginRight: this.props.previewWidth + 'px' }}>
                    <div className="Carousel-img-container" style={imgContainerStyle}>
                        <div className="Carousel-img-container-inner" >
                            {imgDiv}
                        </div>
                        {arrowLeftDiv}
                        {arrowRightDiv}
                        {indicatorDiv}
                    </div>
                    {previewDiv}
                </div>
                {captionBottomDiv}
            </div>
        );
    }
}

Carousel.defaultProps = {
    aspectRatio: 1, /* e.g. 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    backgroundColor: '#ffffff',
    captionPosition: 'bottom',
    hideNavigation: false,
    hideIndicator: false,
    hidePreview: true,
    previewWidth: 140,
    previewRows: 5,
    src: []
};

Carousel.propTypes = {
    aspectRatio: PropTypes.number,
    backgroundColor: PropTypes.string,
    captionPosition: PropTypes.string,
    hideNavigation: PropTypes.bool,
    hideIndicator: PropTypes.bool,
    hidePreview: PropTypes.bool,
    previewWidth: PropTypes.number,
    previewRows: PropTypes.number,
    src: PropTypes.array
}