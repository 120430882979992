import React from 'react';
import PropTypes from 'prop-types';
import { scrollTo, getLocationHashId } from '../App'
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import ArrowButton from '../component/ArrowButton'
import BookishContainer from '../component/BookishContainer'
import BubbleContainer from '../component/BubbleContainer'
import Carousel from '../component/Carousel'
import Slogan from '../component/Slogan'
import Footer from '../component/Footer'
import leistungsdreieck_text from '../media/leistungsdreieck_text.svg';
import leistungsdreieck_000000 from '../media/leistungsdreieck_000000.svg';
/*import digitalsignage_ffffff from '../media/digitalsignage_ffffff.svg';*/
import webapp_ffffff from '../media/webapp_ffffff.svg';
import webapp_000000 from '../media/webapp_000000.svg';
import mobileapp_ffffff from '../media/mobileapp_ffffff.svg';
import mobileapp_000000 from '../media/mobileapp_000000.svg';
import desktopapp_ffffff from '../media/desktopapp_ffffff.svg';
import desktopapp_000000 from '../media/desktopapp_000000.svg';
import cloudsystem_ffffff from '../media/cloudsystem_ffffff.svg';
import professionalservices_ffffff from '../media/professionalservices_ffffff.svg';
import design_ffffff from '../media/design_ffffff.svg';
import './Services.css';

class Services extends React.Component {

    componentDidMount() {
        if (scrollTo(this.props.scrollto) === false) {
            if (scrollTo(getLocationHashId()) === false) {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }
        }
    }

    componentDidUpdate() {
        scrollTo(this.props.scrollto);
    }

    render() {
        return (
            <div className="App-services">
                <div className='App-services-top-right-corner'>
                    <div className='App-services-top-right-corner-inner'></div>
                </div>

                {/* Slogan */}
                <BookishContainer className="App-slogan-container">
                    <Slogan className="App-slogan" />
                </BookishContainer>

                {/* Intro */}
                <BookishContainer id="intro" header={this.props.t('Common.Services')} subheader={this.props.t('Services.SubHeader')} backgroundColor="#95A6B1" topLeftBorderBackgroundColor="#FFFFFF">
                    <IntroSection />
                </BookishContainer>

                {/* Mobile Apps */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="mobileapps" header={this.props.t('Common.MobileApps')} iconsrc={mobileapp_ffffff}>
                    <MobileAPPSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <MobileAPPDetailsSection />
                </BookishContainer>

                {/* Web Apps */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="webapps" header={this.props.t('Common.WebApps')} iconsrc={webapp_ffffff}>
                    <WebAPPSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <WebAPPDetailsSection />
                </BookishContainer>

                {/* Desktop Apps */}
                <div className="App-services-leistungsdreieck-container">
                <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="desktopapps" header={this.props.t('Common.DesktopApps')} iconsrc={desktopapp_ffffff}>
                    <DesktopAppSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <DesktopAppDetailSection />
                </BookishContainer>

                {/* Cloud System */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="cloudsystems"  header={this.props.t('Common.CloudSystems')} iconsrc={cloudsystem_ffffff}>
                    <CloudSystemSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <CloudSystemDetailsSection />
                </BookishContainer>

                {/* Professional Services */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="professionalservices" header={this.props.t('Common.ProfessionalServices')} iconsrc={professionalservices_ffffff}>
                    <ProfessionalServicesSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <ProfessionalServicesDetailsSection />
                </BookishContainer>

                {/* Grafik & Interfacedesign */}
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="design" header={this.props.t('Common.Design')} iconsrc={design_ffffff}>
                    <DesignSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#000000" textColor="#FFFFFF">
                    <DesignReferencesSection />
                </BookishContainer>
                <BookishContainer backgroundColor="#D5DBE0">
                    <DesignDetailsSection />
                </BookishContainer>

                {/* Digital Signage 
                <div className="App-services-leistungsdreieck-container">
                    <img src={leistungsdreieck_000000} alt="leistungsdreieck" />
                </div>
                <BubbleContainer id="digitalsignage" header="Digital Signage" iconsrc={digitalsignage_ffffff}>
                    <DigitalSignageSection />
                </BubbleContainer>
                <BookishContainer backgroundColor="#D5DBE0" bottomRightBorderBackgroundColor="#000000">
                    <DigitalSignageDetailsSection />
                </BookishContainer>
                */}

                {/* Contact */}
                <BookishContainer id="contact" header="" textColor="#FFFFFF" backgroundColor="#000000" topLeftBorderBackgroundColor="#D5DBE0">
                    <Footer hasDownsize />
                </BookishContainer>
            </div>
        );
    }
}

Services.defaultProps = {
    scrollto: ''
};

Services.propTypes = {
    scrollto: PropTypes.string
}

function IntroSection() {
    return (
        <div className="App-services-intro">
            <img src={leistungsdreieck_text} alt="leistungsdreieck" />
        </div>
    );
}

function MobileAPPSection() {
    return (
        <div className="App-services-mobileapp">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.MobileApps.Header')}}></span>
            <CrossPlatformDevelopment hasmobileapp={false} haswebapp={true} hasdesktopapp={true} />
        </div>
    );
}

function MobileAPPDetailsSection() {
    return (
        <div className="App-services-details App-services-mobileapp-details">
            <div className="left">
                <img src="/images/references/steigein_fahrapp.webp" alt="steigein_fahrapp" />
            </div>
            <div className="right">
                <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.MobileApps.Content')}}></span>
                <div className='ArrowButton-container'>
                    <ArrowButton className="ArrowButton-references" text={i18next.t('Common.References')} navigateto="/references#mobileapps" />
                </div>
            </div>
        </div>
    );
}

function WebAPPSection() {
    return (
        <div className="App-services-webapp">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.WebApps.Header')}}></span>
            <CrossPlatformDevelopment hasmobileapp={true} haswebapp={false} hasdesktopapp={true} />
        </div>
    );
}

function WebAPPDetailsSection() {
    return (
        <div className="App-services-details App-services-webapp-details">
            <div className="left">
                <img src="/images/references/bmvit.webp" alt="bmvit" />
            </div>
            <div className="right">
                <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.WebApps.Content')}}></span>
                <div className='ArrowButton-container'>
                    <ArrowButton className="ArrowButton-references" text={i18next.t('Common.References')} navigateto="/references#webapps" />
                </div>
            </div>
        </div>
    );
}

function DesktopAppSection() {
    return (
        <div className="App-services-desktopapp">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.DesktopApps.Header')}}></span>
            <CrossPlatformDevelopment hasmobileapp={true} haswebapp={true} hasdesktopapp={false} />
        </div>
    );
}

function DesktopAppDetailSection() {
    return (
        <div className="App-services-details App-services-desktopapp-details">
            <div className="left">
                <img src="/images/references/SpringerFachmedien_DesktopApp_1400x1080.webp" alt="Springer Fachmedien" />
            </div>
            <div className="right">
                <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.DesktopApps.Content')}}></span>
                <div className='ArrowButton-container'>
                    <ArrowButton className="ArrowButton-references" text={i18next.t('Common.References')} navigateto="/references#desktopapps" />
                </div>
            </div>
        </div>
    );
}

function CloudSystemSection() {
    return (
        <div className="App-services-cloudsystem">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Header')}}></span>
        </div>
    );
}

function CloudSystemDetailsSection() {
    return (
        <div className="App-services-details App-services-cloudsystem-details">
            <span>{i18next.t('Services.CloudSystems.SubHeader')}</span>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Security')}}></span>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Flexibility')}}></span>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Expandable')}}></span>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.CloudSystems.Costs')}}></span>
            <div className='ArrowButton-container'>
                <ArrowButton className="ArrowButton-references" text={i18next.t('Common.References')} navigateto="/references#cloudsystems" />
            </div>
        </div>
    );
}

function ProfessionalServicesSection() {
    return (
        <div className="App-services-professionalservices">
            <span>{i18next.t('Services.ProfessionalServices.Header')}</span>
        </div>
    );
}

function ProfessionalServicesDetailsSection() {
    return (
        <div className="App-services-details App-services-professionalservices-details">
            <div className="left">
                <img src="/images/references/ProfessionalServices_1400x1080.webp" alt="Professional Services" />
            </div>
            <div className="right">
                <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.ProfessionalServices.Content')}}></span>
            </div>
        </div>
    );
}

function DesignSection() {
    return (
        <div className="App-services-design">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.Design.Header')}}></span>
        </div>
    );
}

function DesignReferencesSection() {
    const carouselSrc = [
        { 'path': '/images/references/Lanzendorfer_Logo_1400x791.webp', 'header': 'LANZENDORFER', 'text': i18next.t('Services.Design.References.Lanzendorfer') },
        { 'path': '/images/references/Steigein_FahrApp_1400x791.webp', 'header': 'Steig ein! Fahr App', 'text': i18next.t('Services.Design.References.SteigEin') },
        { 'path': '/images/references/Bmvit_AD_1400x791.webp', 'header': 'BMVIT', 'text': i18next.t('Services.Design.References.BMVIT') },
        { 'path': '/images/references/HE_Lehrmittel_1400x791.webp', 'header': 'Hubert Ebner Verlag', 'text': i18next.t('Services.Design.References.HE') },
        { 'path': '/images/references/SchieneOOE_NWR_1400x791.webp', 'header': 'Schiene OÖ', 'text': i18next.t('Services.Design.References.SchieneOÖ') },
        { 'path': '/images/references/Varena_1400x791.webp', 'header': 'Varena', 'text': i18next.t('Services.Design.References.Varena') }
    ];
    return (
        <div className="App-services-design-references">
            <div className="App-services-design-references-header">
                <span className="eurostile-condensed-heavy">{i18next.t('Services.Design.Title')}</span>
            </div>
            <Carousel className="App-services-design-references-carousel" aspectRatio={0.7} src={carouselSrc} captionPosition="top" backgroundColor="#000000" hidePreview={false} previewWidth={170} />
        </div>
    );
}

function DesignDetailsSection() {
    return (
        <div className="App-services-details App-services-design-details">
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.Design.Content1.Title')}}></span>
            <span>{i18next.t('Services.Design.Content1.Text')}</span>
            <span dangerouslySetInnerHTML={{ __html: i18next.t('Services.Design.Content2.Title')}}></span>
            <span>{i18next.t('Services.Design.Content2.Text')}</span>
            {/*
            <div className='ArrowButton-container'>
                <ArrowButton className="ArrowButton-references" text="Referenzen" navigateto="/references#design" />
            </div>
            */}
        </div>
    );
}

/*
function DigitalSignageSection() {
    return (
        <div className="App-services-digitalsignage">
            <span>Die digitale Welt eröffnet neue Wege, um visuelle Botschaften zu transportieren. Bewegte Bilder, Sound und Filme sind emotional, sprechen an und machen neugierig. Mit einer digitalen Präsentation ihres Unternehmens oder Produktes ziehen Sie die Blicke auf sich. Wecken Sie Interesse und erzeugen Sie Emotionen bei Ihren Kunden direkt am „Point of Sale“.</span>
        </div>
    );
}

function DigitalSignageDetailsSection() {
    function moreClick() {
        window.open("https://www.posscreen.at/", "_blank").focus();
    }
    return (
        <div className="App-services-details App-services-digitalsignage-details">
            <div className="left">
                <img src="/images/references/bank.webp" alt="bank" />
            </div>
            <div className="right">
                <span>Die <b>Software „NewsRodeo“</b> vereinfacht das Gestalten und Planen der Programmabläufe auf Monitoren aller Art. Außerdem implementieren wir bei Bedarf kundenspezifische Komponenten und Schnittstellen zu Ihrem System.</span>
                <div className='ArrowButton-container'>
                    <ArrowButton className="ArrowButton-more" text="mehr" onClick={moreClick} />
                </div>
            </div>
        </div>
    );
}
*/

function CrossPlatformDevelopment(props) {
    let mobileAPPDiv;
    if (props.hasmobileapp === true || props.hasmobileapp === 'true' || props.hasmobileapp === 'True') {
        mobileAPPDiv = (
            <div className="App-services-crossplatform-item" onClick={() => scrollTo('mobileapps')}>
                <img src={mobileapp_000000} alt="mobileapp" />
                <span className="eurostile-condensed-heavy">{i18next.t('Services.CrossPlatform.MobileApp')}</span>
            </div>
        );
    }
    let webAPPDiv;
    if (props.haswebapp === true || props.haswebapp === 'true' || props.haswebapp === 'True') {
        webAPPDiv = (
            <div className="App-services-crossplatform-item" onClick={() => scrollTo('webapps')}>
                <img src={webapp_000000} alt="webapp" />
                <span className="eurostile-condensed-heavy">{i18next.t('Services.CrossPlatform.WebApp')}</span>
            </div>
        );
    }
    let desktopAPPDiv;
    if (props.hasdesktopapp === true || props.hasdesktopapp === 'true' || props.hasdesktopapp === 'True') {
        desktopAPPDiv = (
            <div className="App-services-crossplatform-item" onClick={() => scrollTo('desktopapps')}>
                <img src={desktopapp_000000} alt="webapp" />
                <span className="eurostile-condensed-heavy">{i18next.t('Services.CrossPlatform.DesktopApp')}</span>
            </div>
        );
    }
    return (
        <div className="App-services-crossplatform">
            <div className="App-services-crossplatform-header">{i18next.t('Services.CrossPlatform.Header')}</div>
            {mobileAPPDiv}
            {webAPPDiv}
            {desktopAPPDiv}
        </div>
    );
}

export default withTranslation()(Services);