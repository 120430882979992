import React from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import Carousel from '../component/Carousel'
import './HomeLocations.css';

class HomeLocations extends React.Component {
    render() {
        const thalheimSrc = [
            { 'path': '/images/locations/thalheim01_1400x932.webp' },
            { 'path': '/images/locations/thalheim02_1400x932.webp' },
            { 'path': '/images/locations/thalheim03_1400x932.webp' },
            { 'path': '/images/locations/thalheim04_1400x932.webp' },
            { 'path': '/images/locations/thalheim05_1400x932.webp' },
            { 'path': '/images/locations/thalheim06_1400x932.webp' }
        ];
        const wienSrc = [
            { 'path': '/images/locations/Vienna_DSC_9741.webp' },
            { 'path': '/images/locations/Vienna_DSC_9711.webp' },
            { 'path': '/images/locations/Vienna_DSC_9658.webp' },
            { 'path': '/images/locations/Vienna_DSC_9670.webp' },
            { 'path': '/images/locations/Vienna_DSC_9704.webp' },
            { 'path': '/images/locations/Vienna_DSC_9606.webp' },
            { 'path': '/images/locations/Vienna_DSC_9626.webp' },
            { 'path': '/images/locations/Vienna_DSC_9640.webp' },
            { 'path': '/images/locations/Vienna_DSC_9635.webp' }
        ];
        return (
            <div className="App-home-locations">
                <div className="App-home-location App-home-location-thalheim">
                    <div className="App-home-location-carousel App-home-location-carousel-thalheim">
                        <Carousel aspectRatio={0.6657} src={thalheimSrc} />
                    </div>  
                    <div className="App-home-location-header App-home-location-header-thalheim eurostile-condensed-heavy">
                        <span className="App-home-location-header-type">{this.props.t('HomeLocations.HeaderTypeThalheim')}</span>
                        <span className="App-home-location-header-name">({this.props.t('HomeLocations.HeaderNameThalheim')})</span>
                    </div>
                    <div className="App-home-location-content App-home-location-content-thalheim">
                        <span dangerouslySetInnerHTML={{ __html: i18next.t('HomeLocations.ContentThalheim')}}></span>                       
                    </div>
                </div>
                <div className="App-home-location App-home-location-wien">
                    <div className="App-home-location-carousel App-home-location-carousel-wien">
                        <Carousel aspectRatio={0.6657} src={wienSrc} />
                    </div>  
                    <div className="App-home-location-header App-home-location-header-wien eurostile-condensed-heavy">
                        <span className="App-home-location-header-type">{this.props.t('HomeLocations.HeaderTypeWien')}</span>
                        <span className="App-home-location-header-name">({this.props.t('HomeLocations.HeaderNameWien')})</span>
                    </div>
                    <div className="App-home-location-content App-home-location-content-wien">
                        <span dangerouslySetInnerHTML={{ __html: i18next.t('HomeLocations.ContentWien')}}></span> 
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(HomeLocations);