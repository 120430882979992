import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import './i18nextConf';

// important: the analytics service is installed at www.emmgt.at/analytics
// therefore the necessary files are www.emmgt.at/analytics/mamoto.php and www.emmgt.at/analytics/mamoto.js
// to prevent adblockers from blocking i created a hardlink in windows from analytics/mamoto.php -> js/fancy.php 
// and analytics/mamoto.js -> js/fancy.js
const instance = createInstance({
  urlBase: 'https://www.emmgt.at',
  siteId: 1,
  trackerUrl: 'https://www.emmgt.at/js/fancy.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: 'https://www.emmgt.at/js/fancy.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true - we enable linkTracking in App.js to handle SPA frameworks
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST' // we need post to prevent adblockers from blocking
  }
})

ReactDOM.render(
  // we are using the MatomoProvider so we can use the useMatomo hook anywhere within the MatomoProvider component tree
  <React.StrictMode>
     <MatomoProvider value={instance}>
        <App />
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

const EMM = {

  isScreenWidth500: false,
	resizeLock: undefined,

	getScreenWidth: function () {
		if (window.screen && window.screen.width > 0) {
			return window.screen.width;
		}
    return 0;
	},

	getScreenHeight: function () {
		if (window.screen && window.screen.height > 0) {
			return window.screen.height;
		}
    return 0;
	},

  onResize: function() {
    if (typeof EMM.resizeLock !== 'undefined' && EMM.resizeLock >= 0) {
      EMM.resizeLock++;
      return;
    }
    EMM.resizeLock = 0;
		let screenWidth = EMM.getScreenWidth();
    if (EMM.isScreenWidth500 === true && screenWidth >= 500) {
      EMM.isScreenWidth500 = false;
      let mvp = document.getElementById('viewport');
      mvp.setAttribute('content','width=device-width, initial-scale=1.0, user-scalable=0');
    }
    else if (EMM.isScreenWidth500 === false && screenWidth > 0 && screenWidth < 500) {
      EMM.isScreenWidth500 = true;
      let mvp = document.getElementById('viewport');
      mvp.setAttribute('content','width=500, user-scalable=0');
    }
    setTimeout(function () {
      if (typeof EMM.resizeLock !== 'undefined' && EMM.resizeLock > 0) {
        EMM.resizeLock = undefined;
        EMM.onResize();
      } else {
        EMM.resizeLock = undefined;
      }
    }, 1000);
	},

  hexToRgb: function (hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    var rgb = { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) };
    var rgb255 = { r: rgb.r/255, g: rgb.g/255, b: rgb.b/255 };
    return {
      hex: hex,
      rgb: "rgb("+rgb.r+", "+rgb.g+", "+rgb.b+")",
      rgb255: "["+rgb255.r+","+rgb255.g+","+rgb255.b+",1]"
    };
  },

  buildHyperlink: function (path) {
		let origin = '';
    if (document.location && document.location.origin && document.location.origin.length > 0) {
      origin = document.location.origin;
    }
    if (path && path.length > 0) {
      origin = origin + path;
    }
    return origin;
	},

  run: function () {
    window.onresize = EMM.onResize;
    EMM.onResize();
  }
}
document.EMM = EMM;
EMM.run();

document.body.appendChild(document.createComment(' Hallo Schnüffler! ^⨀ᴥ⨀^\nSchau mal bei ' + EMM.buildHyperlink('/career') + ' vorbei.\nDa haben wir einen Knochen verbuddelt. '));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
